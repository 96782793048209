/* eslint-disable react/prop-types */
// import { PropTypes } from "prop-types";

import React from "react";
import { graphql } from "gatsby";
import tw from "twin.macro";
import * as T from "~components/styles/Typography.jsx";
import Footer from "~components/Footer.jsx";
import Layout from "~components/Layout.jsx";
import SEO from "~components/SEO.jsx";
import BannerSection from "~components/sections/BannerSection.jsx";
import ImageArticleSection from "~components/sections/ImageArticleSection.jsx";
import PrivacyContent from "../components/sections/PrivacyContent";

const PrivacyPolicyPage = ({ data, location }) => {
  // ===========================================================================
  // variables

  const cms = data.sanityPrivacyPage;

  // ===========================================================================
  // render

  return (
    <>
      <SEO
        customTitle={cms?.title}
        customDescription={cms?.seoDescription}
        customKeywords={cms?.seoKeywords}
        path={location.pathname}
      />

      <Layout>
        <BannerSection data={cms.atfBanner} />

        <ImageArticleSection data={cms.textImage} />

        {cms.contents.map((content) => (
          <PrivacyContent key={content._key} content={content} />
        ))}
      </Layout>

      <Footer />
    </>
  );
};

export default PrivacyPolicyPage;

export const query = graphql`
  query PrivacyPolicyPage {
    sanityPrivacyPage {
      title

      #

      atfBanner {
        textColor {
          hex
        }
        backgroundColor {
          hex
        }
        header
        subheader
        image {
          altText
          asset {
            gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP, AVIF])
          }
        }
      }

      #

      textImage {
        textColor {
          hex
        }
        backgroundColor {
          hex
        }
        header
        _rawBody
        image {
          altText
          asset {
            gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP, AVIF])
          }
        }
        imageAlign
      }

      #

      contents {
        _key
        textColor {
          hex
        }
        backgroundColor {
          hex
        }
        header
        _rawBody
      }

      #

      seoDescription
      seoKeywords
    }
  }
`;
