/* eslint-disable react/prop-types */
// import { PropTypes } from "prop-types";

import React from "react";
import tw, { css, theme } from "twin.macro";
import Grid from "~components/styles/Grid.jsx";
import * as A from "~components/styles/Animations.jsx";
import * as T from "~components/styles/Typography.jsx";
import PortableText from "~components/PortableText.jsx";

const PrivacyContent = ({ content }) => {
  const { backgroundColor, textColor, header, _rawBody } = content;
  return (
    <section
      css={[
        css`
          background-color: ${backgroundColor?.hex || `white`};
          color: ${textColor?.hex || `black`};
        `,
        tw`relative block`
      ]}
    >
      <Grid>
        <div tw="col-span-full md:col-span-12 py-12 md:px-[60px] md:py-[72px]">
          <T.Head font="3">{header}</T.Head>
        </div>

        <div tw="col-span-full md:col-span-12 pb-12 md:pb-0 md:px-[60px] md:py-[72px]">
          <PortableText blocks={_rawBody} />
        </div>
      </Grid>
    </section>
  );
};

export default PrivacyContent;
